import React, { useEffect, useState } from "react"
import { useAuth } from "../../context/AuthContext"
import LoginForm from "./LoginForm"
import { getAuth, signOut } from "firebase/auth"
import {
  getFirestore,
  query,
  collection,
  onSnapshot,
  orderBy,
  limit,
} from "firebase/firestore"
import PortfolioTable from "../UI/Tables/PortfolioTable"
import { ButtonSimple } from "../UI/Buttons/Buttons"
import AddNewAssetForm from "./Forms/AddNewAssetForm"
import AddNewTransactionForm from "./Forms/AddNewTransactionForm"
import AddNewCashflowForm from "./Forms/AddNewCashflowForm"
import { deleteSnapshotsAfter } from "../../firebase/firestore"

const Admin = () => {
  const [holdings, setHoldings] = useState([])
  const [ecosystems, setEcosystems] = useState([])
  const [categories, setCategories] = useState([])
  const [assets, setAssets] = useState([])
  const [investors, setInvestors] = useState([])
  const { isSignedIn } = useAuth()
  const [showNewAssetForm, setShowNewAssetForm] = useState(false)
  const [showAddTransactionForm, setshowAddTransactionForm] = useState(false)
  const [showNewCashflowForm, setShowNewCashflowForm] = useState(false)
  const snapshotListeners = []

  const addData = querySnapshot => {
    const holdings = []
    const snapShotHoldings = querySnapshot.docs[0].data()
    snapShotHoldings.holdings.forEach(h => {
      holdings.push(h)
      /* holdings.push(
        {
        name: h.asset.name,
        symbol: h.asset.symbol,
        bucket: h.asset.categoryName,
        currentHoldings: h.units,
        currentPrice: h.price.price,
        mv: h.price.price * h.units
      }) */
    })
    setHoldings(holdings)
  }

  const addEcosystem = querySnapshot => {
    const ecosystems = []
    querySnapshot.forEach(doc => {
      ecosystems.push({
        name: doc.data().name,
        id: doc.id,
        value: doc.data().name,
        label: doc.data().name,
      })
    })
    setEcosystems(ecosystems)
  }

  const addCategories = querySnapshot => {
    const categories = []
    querySnapshot.forEach(doc => {
      categories.push({
        name: doc.data().name,
        id: doc.id,
        value: doc.data().name,
        label: doc.data().name,
      })
    })
    setCategories(categories)
  }

  const addInvestors = querySnapshot => {
    const investors = []
    querySnapshot.forEach(doc => {
      investors.push({
        name: doc.data().name,
        id: doc.id,
        value: doc.data().name,
        label: doc.data().name,
      })
    })
    setInvestors(investors)
  }
  const addAssets = querySnapshot => {
    const assets = []
    querySnapshot.forEach(doc => {
      assets.push({
        value: doc.data().name,
        label: doc.data().name,
        name: doc.data().name,
        id: doc.id,
      })
    })
    setAssets(assets)
  }

  useEffect(() => {
    const q = query(
      collection(getFirestore(), "snapshots"),
      orderBy("timestamp", "desc"),
      limit(1)
    )
    const ecosystemsQuery = query(collection(getFirestore(), "ecosystems"))
    const categoriesQuery = query(collection(getFirestore(), "categories"))
    const assetsQuery = query(collection(getFirestore(), "assets"))
    const investorsQuery = query(collection(getFirestore(), "investors"))
    try {
      let listener = onSnapshot(q, querySnapshot => addData(querySnapshot))
      snapshotListeners.push(listener)
      let ecosystemListener = onSnapshot(ecosystemsQuery, querySnapshot =>
        addEcosystem(querySnapshot)
      )
      snapshotListeners.push(ecosystemListener)
      let categoriesListener = onSnapshot(categoriesQuery, querySnapshot =>
        addCategories(querySnapshot)
      )
      snapshotListeners.push(categoriesListener)
      let investorsListener = onSnapshot(investorsQuery, querySnapshot =>
        addInvestors(querySnapshot)
      )
      snapshotListeners.push(investorsListener)
      let assetsListener = onSnapshot(assetsQuery, querySnapshot =>
        addAssets(querySnapshot)
      )
      snapshotListeners.push(assetsListener)
    } catch (err) {
      console.error(err)
    }
    return () => snapshotListeners.forEach(l => l())
  }, [])

  if (!isSignedIn) {
    return (
      <div>
        <LoginForm />
      </div>
    )
  }

  return (
    <div>
      <div className="h-16 bg-gray-300 flex flex-row">
        <div className="w-full flex items-center mx-3">
          <h1>Admin Dashboard</h1>
        </div>

        <div className="w-40 flex justify-center items-center">
          <button
            type="button"
            className="m-3 px-4 py-1 bg-gradient-to-r from-red-600 to-red-800 text-gray-100 rounded "
            onClick={() => signOut(getAuth())}
          >
            Sign Out
          </button>
        </div>
      </div>
      <PortfolioTable holdings={holdings} />
      <div className="flex-row flex justify-center w-full my-4">
        <div className="mx-4">
          <ButtonSimple
            handleClick={() => setShowNewAssetForm(prevState => !prevState)}
            text="Add new asset"
          />
        </div>
        <div className="mx-4">
          <ButtonSimple
            handleClick={() =>
              setshowAddTransactionForm(prevState => !prevState)
            }
            text="Add new transaction"
          />
        </div>
        <div className="mx-4">
          <ButtonSimple
            handleClick={() => setShowNewCashflowForm(prevState => !prevState)}
            text="Add new cashflow"
          />
        </div>
      </div>
      <div className="flex justify-center">
        {showNewAssetForm ? (
          <AddNewAssetForm categories={categories} ecosystems={ecosystems} />
        ) : null}
        {showAddTransactionForm ? (
          <AddNewTransactionForm assets={assets} />
        ) : null}
        {showNewCashflowForm ? (
          <AddNewCashflowForm investors={investors} />
        ) : null}
      </div>
      <ButtonSimple
        text="Delete snapshots"
        type="button"
        handleClick={() => deleteSnapshotsAfter(new Date(Date.UTC(2019, 7, 5)))}
      />
    </div>
  )
}

export default Admin
