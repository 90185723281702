import React from "react"
import DatePicker from "react-datepicker"
import { useField, useFormikContext, ErrorMessage } from "formik";
import Select from "react-select"
import "react-datepicker/dist/react-datepicker.css"

export const SimpleInput = ({errors, label, name, placeholder, type}) => {
  const [field, meta, helpers] = useField(name)
    return (
      <div className="sm:col-span-3">
        <label htmlFor={label} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <input
            type={type}
            name={name}
            id={name}
            value={field.value}
            onChange={(value)=>helpers.setValue(value)}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder={placeholder}
            {...field}
            step='any'
          />
        </div>
        {errors === undefined ? null: <p className="mt-2 text-sm text-red-600">
        {errors}
      </p> }
      </div>
    )
  }

export const FieldSelect = ({name, options, label, resetFormHandler}) => {
  const [field, meta, helpers] = useField(name)
  return (
    <div className="sm:col-span-3">
    <label htmlFor={name} className="block text-sm font-medium text-gray-700">
                {label}
              </label>
      <Select
        className="sm:col-span-3 mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
        name={name}
        value={field.value}
        onChange={(value)=>{
          if (resetFormHandler) {
            resetFormHandler()
          }
          helpers.setValue(value)}}
        options={options}
        onBlur={() => helpers.setTouched(true)}
      />
      <ErrorMessage name={name} />
    </div>
  )
}


export const DatePickerInput = ({label, ...props}) => {
  const {setFieldValue} = useFormikContext()
  const [field] = useField(props)
  return (
    <div className="sm:col-span-3">
      <label htmlFor={field.name} className="block text-sm font-medium text-gray-700">
                {label}
              </label>

      <DatePicker {...field} {...props} selected={(field.value && new Date(field.value)) || null}
      className="sm:col-span-3 mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
      onChange={val => {
        setFieldValue(field.name, val);
      }}
      timeInputLabel="Time:"
      dateFormat="dd.MM.yyyy hh:mm"
      showTimeInput/>

    </div>
  )
}

