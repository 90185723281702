import { Link } from "gatsby"
import React from "react"

export const ButtonLink = ({ link }) => {
  return (
    <Link to={link}>
      <button className="bg-blue-500 text-white font-medium py-1 px-3 rounded text-base">
        Sign Up
      </button>
    </Link>
  )
}

export const ButtonSimple = ({ handleClick, text, type="button" }) => {
  return (
    <button
      type={type}
      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      onClick={() => handleClick()}
    >
      {text}
    </button>
  )
}
