import React, { useState } from "react"
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"
import {DatePickerInput, SimpleInput, FieldSelect} from "../../UI/Forms/InputFields"
import { ButtonSimple } from "../../UI/Buttons/Buttons"
import { addNewTransaction, getPriceEODfromDB } from "../../../firebase/firestore"

const transactionTypes = [
  {value:'trade', label:'Trade'},
  {value:'staking', label:'Staking Income'},
  {value:'mining', label:'Mining Reward'},
  {value:'airdrop', label:'Airdrop'},
  {value:'adjustments', label:'Adjustment'}
]

const validationSchema = Yup.object().shape({
    //type:Yup.string().oneOf(transactionTypes).required(),
    //buyAssetName:Yup.string().required(),
    buyAssetUnits:Yup.number().required().positive('Needs to be positive'),
    //sellAssetName:Yup.string().required(),
    //sellAssetUnits:Yup.number().required(),
    timestamp: Yup.date().required(),
    usdValue:Yup.number().required()
})

const getPrice = async(assetId, timestamp, fieldSetter, units) => {
  try{
    const result = await getPriceEODfromDB(assetId,timestamp)
    fieldSetter('buyAssetPrice', +result.price.toFixed(8))
    fieldSetter('usdValue', +(result.price * units).toFixed(4))
    return
  } catch(error) {
    throw new Error(error)
  }
}

const AddNewTransactionForm = ({assets}) => {
  const [submitErrors, setSubmitErrors] = useState(null)
    return(
            <Formik
                initialValues={{
                    type:{value:'trade', label:'Trade'},
                    buyAsset:null,
                    buyAssetUnits:0,
                    buyAssetPrice:0,
                    sellAsset:null,
                    sellAssetUnits:0,
                    timestamp: new Date(),
                    usdValue:0
                }}
                validationSchema={validationSchema}
                onSubmit={async values => {
                  setSubmitErrors(null)
                  try {
                    await addNewTransaction(values)
                  } catch(err) {
                    //alert(`Error in submitting transaction: ${err.message}`)
                    setSubmitErrors(err.message)
                  }}}
            >
                {({errors, values, resetForm, isSubmitting, setFieldValue}) => {
                  return(
                    <Form className="space-y-8 divide-y divide-gray-200 w-1/2">
                      <div className="space-y-8 divide-y divide-gray-200">
                        <div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900">New transaction</h3>
                          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <div className="sm:col-span-6">
                            <FieldSelect name='type' label="Transaction type" options={transactionTypes} resetFormHandler={resetForm}/>
                            </div>
                            <FieldSelect name='buyAsset' options={assets} label={values.type.value === 'trade' ? 'Buy Asset' : 'Asset'}/>
                            <SimpleInput name="buyAssetUnits" placeholder={0.00} label="Units" type="number" errors={errors.buyAssetUnits}/>
                            {values.type.value === 'trade' ? <>
                              <FieldSelect name='sellAsset' options={assets} label="Sell Asset"/>
                              <SimpleInput name="sellAssetUnits" placeholder={0} label="Units" type="number" errors={errors.sellAssetUnits}/> </>
                              : null}
                            <Field name='timestamp' as={DatePickerInput} label="Transaction time"/>
                            <div className="sm:col-span-3 flex align-bottom m-4">
                            <ButtonSimple text="Get price" type='button' handleClick={()=>getPrice(values.buyAsset.id,values.timestamp, setFieldValue, values.buyAssetUnits)} />
                            </div>
                            <SimpleInput name='buyAssetPrice' placeholder={0} label='Buy Asset Price' type='number' errors={errors.buyAssetPrice} />
                            <SimpleInput name="usdValue" placeholder={0} label="USD value" type="number" errors={errors.usdValue} />  
                          </div>
                          <div className="flex flex-col justify-center items-center col-span-6 my-4">
                          {isSubmitting ? <p>Submitting...</p> :
                          <ButtonSimple text="Submit" type='submit' handleClick={()=> null}/>}
                          {submitErrors ? <p className="w-full text-red-600 mt-2">{submitErrors}</p>:null}
                          </div>
                        </div>
                      </div>
                    </Form>
                  )
                }}

            </Formik>

    )
}

export default AddNewTransactionForm