import React from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { addNewAsset } from "../../../firebase/firestore"
import { FieldSelect, SimpleInput } from "../../UI/Forms/InputFields"
import { ButtonSimple } from "../../UI/Buttons/Buttons"


const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Required!"),
  symbol: Yup.string().required("Required!").uppercase("All uppercase please"),
  coingeckoSymbol:Yup.string().required(),
  colorCode:Yup.object().shape({
    r:Yup.number().required().min(0).max(255),
    g:Yup.number().required().min(0).max(255),
    b:Yup.number().required().min(0).max(255)
  })
})

const AddNewAssetForm = ({categories, ecosystems}) => {
  return (
      <Formik
        initialValues={{
          name: "",
          symbol: "",
          category:null,
          ecosystem:null,
          coingeckoSymbol:'',
          colorCode:{r:255,g:255,b:255},
        }}
        validationSchema={ValidationSchema}
        onSubmit={async values => {
          await addNewAsset(values)
        }}
      >
        {({ errors, values, isSubmitting }) => (
          <Form className="space-y-8 divide-y divide-gray-200 w-1/2">
            <div className="space-y-8 divide-y divide-gray-200">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">New asset</h3>
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-6"></div>
                    <SimpleInput name="symbol" label="Symbol" errors={errors.symbol} placeholder="BTC" type="text" />
                    <SimpleInput name="name" label="Name" errors={errors.name} placeholder="Bitcoin" type="text" />
                    <FieldSelect name="category" label="Category" options={categories} />
                    <FieldSelect name="ecosystem" label="Ecosystem" options={ecosystems} />
                    <SimpleInput name="coingeckoSymbol" label="Coingecko Symbol" errors={errors.coingeckoSymbol} placeholder="bitcoin" type="text" />
                  </div>
                  <div className="flex flex-col justify-center items-center col-span-6 my-4">
                          {isSubmitting ? <p>Submitting...</p> :
                  <ButtonSimple
                  handleClick={()=> null}
                    type="submit"
                    text="Submit"
                  />}
                  <button type='submit'>submit</button>
                  </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
  )
}

export default AddNewAssetForm
