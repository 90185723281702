import { firestore } from "./fb"
import { collection, addDoc, doc, query, orderBy, limit, getDoc, getDocs, updateDoc, arrayUnion, Timestamp, where, deleteDoc } from "firebase/firestore"
import {cloneDeep} from "lodash"

export const addNewAsset = async assetData => {
  try {
    await addDoc(collection(firestore, 'assets'), {
      name: assetData.name,
      symbol: assetData.symbol,
      categoryName: assetData.category.name,
      categoryRef: doc(firestore,`categories/${assetData.category.id}`),
      ecosystemName: assetData.ecosystem.name,
      ecosystemRef: doc(firestore, `ecosystems/${assetData.ecosystem.id}`),
      coingeckoSymbol: assetData.coingeckoSymbol,
      colorCode: assetData.colorCode
    })
    return "Success"
  } catch (error) {
    throw new Error("Failed to write asset to db", error)
  }
}

export const addNewTransaction = async transactionData => {
  try {
  const tData = {type: transactionData.type.value,
    buyAssetId: transactionData.buyAsset.id,
    buyAssetUnits: transactionData.buyAssetUnits,
    buyAssetPrice:transactionData.buyAssetPrice,
    sellAssetId: null,
    sellAssetUnits:null,
    sellAssetPrice:null,
    timestamp: transactionData.timestamp,
    usdValue: transactionData.usdValue}
    
    if (tData.type === "trade") {
      tData.sellAssetId=transactionData.sellAsset.id
      tData.sellAssetUnits=transactionData.sellAssetUnits
      tData.sellAssetPrice=tData.usdValue / tData.sellAssetUnits
    }
    
    await addDoc(collection(firestore, 'transactions'), tData)
    // create new snapshot and save post transaction
    const lastSnapshot = await getLatestPortfolioSnapshot(tData.timestamp)
    const buyAsset = await getAssetData(tData.buyAssetId)
    let newHoldings = adjustHoldings(lastSnapshot.holdings, buyAsset, tData.buyAssetUnits, tData.buyAssetPrice, tData.timestamp)
    if (tData.type === 'trade') {
      const sellAsset = await getAssetData(tData.sellAssetId)
      newHoldings = adjustHoldings(newHoldings, sellAsset, -tData.sellAssetUnits, tData.sellAssetPrice, tData.timestamp)
    }
    lastSnapshot.holdings = newHoldings
    lastSnapshot.timestamp = tData.timestamp
    await savePortfolioSnapshot(lastSnapshot)

  } catch(err) {
    throw new Error(`Failed to write transaction to db: ${err}`)
  }
}

export const addNewCashflow = async cashflow => {
  /*
Function description
Adds cash in or outflows to latest portfolio snapshot and saves new snapshot. Assumes USD inflow.
TODO: Adjust for any asset as inflow (get latest price for asset, calculate units, etc...)

1 Get latest snapshot
2 Check if holdings contain USD asset
3 If yes, add cashflow to current USD position
  If no, create USD position in holdings array
4 Adjust units for new inflow
5 TODO: Delete all snapshots after transaction
5 Save transaction to investor object
6 Save snapshot to snapshots collection
*/
  try {
    const lastSnapshot = await getLatestPortfolioSnapshot(cashflow.timestamp)
    const buyAsset = await getAssetData('1WpgfFx2E1A9VPc9Ntld')
    const newHoldings = adjustHoldings(lastSnapshot.holdings, buyAsset, cashflow.usdAmount, Timestamp.fromDate(cashflow.timestamp))

    const lastNAV = Number(lastSnapshot.nav.current)
    const units = Math.floor(cashflow.usdAmount / lastNAV)
    lastSnapshot.holdings = newHoldings
    lastSnapshot.units = Number(lastSnapshot.units) + units
    lastSnapshot.timestamp = Timestamp.fromDate(cashflow.timestamp)
    // save snapshot back to db
    await savePortfolioSnapshot(lastSnapshot)
    
    await saveCashflowToInvestor(cashflow.investor.id,{
        nav:lastNAV,
        units: units,
        usdAmount: cashflow.usdAmount,
        timestamp: lastSnapshot.timestamp
    })
    
} catch(error) {
    throw new Error(error.message)
}
}

export const getLatestPortfolioSnapshot = async (beforeDate = new Date()) => {
  try {
      const querySnapshot = await getDocs(query(collection(firestore,'snapshots'), where('timestamp', '<=', beforeDate), orderBy('timestamp','desc'), limit(1)))
      const result = querySnapshot.docs[0].data()
      return result
  } catch (error) {
      throw new Error(error)
  }
}

export const savePortfolioSnapshot = async (snapshot) => {
  try {
      await addDoc(collection(firestore, 'snapshots'),snapshot)
  } catch(error) {
      throw new Error(error)
  }
}

export const saveCashflowToInvestor = async (investorId, cashflow) => {
  try {
      await updateDoc(doc(firestore,'investors',investorId),{transactions: arrayUnion(cashflow)})
  } catch(error) {
      throw new Error(error)
  }
}

export const getAssetData = async (assetId) => {
  try {
    const result = await getDoc(doc(firestore, 'assets', assetId))
    const asset = result.data()
    asset['id'] = result.id
    return asset
  } catch (error) {
    throw new Error(error)
  }
}

export const getPriceEODfromDB = async (assetId, timestamp) => {
  /* PARAMETERS
     assetId: string
     timestamp: Date object
  // RETURNS
     lastPrice: price object with fields price:number and timestamp:timestamp
  */
 
  const year = timestamp.getFullYear()
  try {
      // get prices array for specific year
      const prices = await getDocs(query(collection(firestore,`assets/${assetId}/priceHistory`),where('year','==',year)))
      // get price for holdings (at last UTC 00:00 time)
      const lastPrice = prices.docs[0].data().prices.filter(p => p.timestamp.toDate() <= timestamp).sort((a,b)=>b.timestamp.toDate()-a.timestamp.toDate())[0]
      return lastPrice
  } catch (error) {
      throw new Error(error)
  }
}

export const adjustHoldings = (holdings, asset, units, price, timestamp) => {
  const newHoldings = cloneDeep(holdings)
  const assetIndex = newHoldings.findIndex(h => h.asset.id === asset.id)
  if (assetIndex === -1) {
      newHoldings.push({
          asset:asset,
          units:units,
          inceptionDate: timestamp,
          price:{
            price:price,
            timestamp:timestamp
          }
      })
  } else {
      const newUnits = Number(newHoldings[assetIndex].units) + units
      newHoldings[assetIndex].units = newUnits
  }
  return newHoldings
}

export const deleteSnapshotsAfter = async (timestamp) => {
  try {
    //timestamp: Timestamp
    const q = query(collection(firestore,'snapshots'), where('timestamp', '>=', timestamp))
    const result = await getDocs(q)
    const promises = result.docs.map(async doc => {
      const promise = await deleteDoc(doc.ref)
      return promise
    })
    await Promise.all(promises)
    alert('Deleted!')
  } catch(error) {
    throw new Error(error)
  }
}