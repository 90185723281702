import React, { useState } from "react"
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"
import {DatePickerInput, SimpleInput, FieldSelect} from "../../UI/Forms/InputFields"
import { ButtonSimple } from "../../UI/Buttons/Buttons"
import { addNewCashflow } from "../../../firebase/firestore"

const validationSchema = Yup.object().shape({
    //investor: Yup.string().required(),
    timestamp: Yup.date().required(),
    usdAmount:Yup.number().required()
})

const AddNewCashflowForm = ({investors}) => {
  const [submitErrors, setSubmitErrors] = useState(null)
    return(
            <Formik
                initialValues={{
                    investor:null,
                    timestamp: new Date(),
                    usdAmount:0
                }}
                validationSchema={validationSchema}
                onSubmit={async values => {
                  setSubmitErrors(null)
                  try {
                    await addNewCashflow(values)
                  } catch(err) {
                    alert(`Error in submitting cashflow: ${err.message}`)
                    setSubmitErrors(err.message)
                  }}}
            >
                {({errors, values, isSubmitting}) => {
                  return(
                    <Form className="space-y-8 divide-y divide-gray-200 w-1/2">
                      <div className="space-y-8 divide-y divide-gray-200">
                        <div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900">New cashflow</h3>
                          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <div className="sm:col-span-6">
                            <FieldSelect name='investor' label="Investor" options={investors}/>
                            </div>
                            <SimpleInput name="usdAmount" placeholder={0.00} label="USD Amount" type="number" errors={errors.usdAmount}/>
                            <Field name='timestamp' as={DatePickerInput} label="Transaction time"/>
                          </div>
                          <div className="flex flex-col justify-center items-center col-span-6 my-4">
                          {isSubmitting ? <p>Submitting...</p> :
                          <ButtonSimple text="Submit" type='submit' handleClick={()=> null}/>}
                          {submitErrors ? <p className="w-full text-red-600 mt-2">{submitErrors}</p>:null}
                          </div>
                        </div>
                      </div>
                    </Form>
                  )
                }}
            </Formik>
    )
}

export default AddNewCashflowForm